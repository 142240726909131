.big-button {
    position: fixed; /* Make the button fixed to the screen */
    left: 0; /* Align the button to the left side */
    bottom: 30%; /* 5% indentation from the bottom */
    width: 60px; /* Adjust the size of the button to be small */
    height: 60px; /* Square shape */
    z-index: 3;
    
    border: none; /* Remove default border */
    display: flex; /* Center the icon inside the button */
    align-items: center; /* Center the icon vertically */
    justify-content: center; /* Center the icon horizontally */
    cursor: pointer; /* Change cursor to pointer */
}

.small-button {
    position: fixed; /* Make the button fixed to the screen */
    left: 0; /* Align the button to the left side */
    bottom: 18%; /* 5% indentation from the bottom */
    width: 60px; /* Adjust the size of the button to be small */
    height: 60px; /* Square shape */
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.3);
    border: none; /* Remove default border */
    display: flex; /* Center the icon inside the button */
    align-items: center; /* Center the icon vertically */
    justify-content: center; /* Center the icon horizontally */
    cursor: pointer; /* Change cursor to pointer */
}


.big-button.playing {
    background-color: rgba(255, 255, 255, 0.3);
}

.big-button.paused {
    background-color: rgba(255, 255, 255, 0.3);
}

.button-icon {
    width: 70%; /* Adjust the icon size as needed */
    height: auto; /* Maintain the aspect ratio */
}
