.pause-panel {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
}

.pause-panel-resume-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 100px;
    height: 40px;
    z-index: 4;
}
