
.onboarding {
    z-index: 5;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.backcontent {
    position: absolute;
    color: white;
    top: 12px;
    align-items: center;
    font-size: 14px;
}


.textstyle {
    font-family: "Alegreya", serif;
    padding-bottom: 1%;
    font-size: 21px;
    color: white;
    margin-left: 10%;
        margin-right: 10%;
}

.textstyletwo {
    font-family: "Alegreya", serif;
    padding-top: 1%;
    font-size: 15px;
    color: white;
    margin-left: 10%;
    margin-right: 10%;
}

.background {
    z-index: 4;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c1c1c;
}

.content {
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6; /* Ensure content is above the background images */
    opacity: 0;
    transform: translateY(10%);
    animation: fadeInMoveUp 2s forwards;
}

@keyframes fadeInMoveUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.onboarding-text {
    padding-bottom: 40px;
    font-family: "Alegreya", serif;
    font-size: 18px;
    color: white;
}

.startbut {
    display: inline-block;
    font-family: "Alegreya", serif;
    padding: 15px 32px;
    font-size: 30px;
    text-align: center;
    /*color: white;*/
    background-color: black;
    border: 2px solid #fff;
}

.inactive {
    color: white;
}

.active {
    color: #212121;
}

.background-image.small {
    width: 200vw; /* Adjust size as needed */
    height: auto; /* Adjust size as needed */
    position: absolute;
    opacity: 0.17;
    bottom: -300px;
    left: -300px;
    animation: moveImage1 27s linear infinite; /* Adjust duration as needed */
}

.background-image.large {
    width: 400vw; /* Adjust size as needed */
    height: auto; /* Adjust size as needed */
    position: absolute;
    opacity: 0.16;
    bottom: 0;
    left: 0;
    animation: moveImage2 30s linear infinite; /* Adjust duration as needed */
}

.background-image-extralarge {
    width: 500vw; /* Adjust size as needed */
    height: auto; /* Adjust size as needed */
    position: absolute;
    opacity: 0.12;
    bottom: -30px;
    left: -30px;
    animation: moveImage4 34s linear infinite; /* Adjust duration as needed */
}

.background-image-backlarge {
    width: 600vw; /* Adjust size as needed */
    height: auto; /* Adjust size as needed */
    position: absolute;
    opacity: 0.1;
    bottom: 5px;
    left: 5px;
    animation: moveImage3 34s linear infinite; /* Adjust duration as needed */
}

.background-image-toplarge {
    width: 400vw; /* Adjust size as needed */
    height: auto; /* Adjust size as needed */
    position: absolute;
    opacity: 0.13;
    bottom: 13px;
    left: 13px;
    animation: moveImage5 29s linear infinite; /* Adjust duration as needed */
}

@keyframes moveImage1 {
    0% {
        transform: translate(0, 0) rotate(-60deg);
    }

    100% {
        transform: translate(calc(100vw + 50px), calc(-100vh - 50px)) rotate(300deg); /* Adjust based on initial size */
    }
}

@keyframes moveImage2 {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }

    100% {
        transform: translate(calc(100vw + 100px), calc(-100vh - 100px)) rotate(280deg);
        /* Adjust based on initial size */
    }
}

@keyframes moveImage3 {
    0% {
        transform: translate(50vw, 50vh) rotate(60deg);
    }

    100% {
        transform: translate(calc(130vw + 50px), calc(-130vh - 50px)) rotate(160deg); /* Adjust based on initial size */
    }
}

@keyframes moveImage4 {
    0% {
        transform: translate(0, 0) rotate(180deg);
    }

    100% {
        transform: translate(calc(130vw + 50px), calc(-130vh - 50px)) rotate(360deg); /* Adjust based on initial size */
    }
}

@keyframes moveImage5 {
    0% {
        transform: translate(10vw, 10vh) rotate(-180deg);
    }

    100% {
        transform: translate(calc(130vw + 50px), calc(-130vh - 50px)) rotate(30deg); /* Adjust based on initial size */
    }
}



.spottop-left {
    position: absolute;
    width: 40vw;
    height: 40vw;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.13;
    background-color: #fff;
    filter: blur(80px);
    animation: growShrink 12s infinite alternate;
}



@keyframes growShrink {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.5);
    }
}

