/* HistoryWidget.css */
.history-widget {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    overflow-y: auto;
    /*margin-top: 15vh;*/
    /*max-height: 80vh;*/ 
    height: 80vh;
    transform: translateY(100%);
    opacity: 0.1;
    animation: fadeInMoveUp 1.7s forwards;
    /*transform: translateY(100%);*/
    z-index: 40;
}


@keyframes fadeInMoveUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.first_img_description {
    text-align: left;
    margin: 0 10px;
    font-family: "Alegreya", serif;
    font-size: 12px;
    color: black;
}

.second_img_description {
    text-align: left;
    margin: 0 10px;
    font-family: "Alegreya", serif;
    font-size: 12px;
    color: black;
}

.modal-overlay {
    position: fixed;
    z-index: 35;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-button {
    position: fixed;
    z-index: 41;
    top: 10px;
    right: 10px;
    font-size: 28px;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.imagewidget {
    max-width: 100%;
    border-radius: 10px;
}

.text-section {
    text-align: left;
    margin: 0 10px;
    font-family: "Alegreya", serif;
    font-size: 20px;
    color: black;
}

.text-style {
    white-space: pre-wrap;
}

.zaglavie {
    text-align: center;
    font-size: 30px;
    color: black;
}
