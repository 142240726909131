.game-over {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.game-over-text {
    padding-top: 120px;
    padding-bottom: 120px;
    font-size: 60px;
}

.game-over-button {
    width: 200px;
    height: 50px;
}
