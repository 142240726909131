.cameraBack {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: fill;
    z-index: -1;
    /*filter: sepia(100%);*/
}
