.startbuttons {
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    font-size: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.textstbut {
    color: white;
}

.content {
    opacity: 0;
    transform: translateY(10%);
    animation: fadeInMoveUp 2s forwards;
}

@keyframes fadeInMoveUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.permissionbutton {
    display: inline-block;
    font-family: "Alegreya", serif;
    padding: 15px 32px;
    font-size: 30px;
    text-align: center;
    color: white;
    background-color: black;
    border: 2px solid #fff;
}

.textcontainer {
    z-index: 5;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textstyle {
    font-family: "Alegreya", serif;
    padding-bottom: 1%;
    font-size: 21px;
    color: white;
    margin-left: 10%;
    margin-right: 10%;
}



    